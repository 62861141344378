import { UploadOutlined } from '@ant-design/icons';
import { Button, Form, Input, notification, Upload } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import Papa from 'papaparse';
import React from 'react';
import styles from './App.module.css';

const storageKeys = ['emailFrom', 'emailSubject', 'emailContent'];

const App = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  return (
    <Form
      className={styles.container}
      layout="vertical"
      hideRequiredMark
      initialValues={storageKeys.reduce((acc, key) => {
        acc[key] = window.localStorage.getItem(key);
        return acc;
      }, {})}
      onFinish={(values) => {
        Papa.parse(values.data.file, {
          header: true,
          skipEmptyLines: 'greedy',
          complete: (results) => {
            storageKeys.forEach((key) =>
              window.localStorage.setItem(key, values[key]),
            );

            const data = new FormData();
            data.append('signature', values.signature.file);
            data.append(
              'data',
              JSON.stringify({
                email: {
                  from: values.emailFrom,
                  subject: values.emailSubject,
                  text: values.emailContent,
                },
                rows: results.data.map((row) => ({
                  instructorTitle: row.Title,
                  instructorName: row.Name,
                  courseTitle: row['Course Title'],
                  courseCode: row['Course Code'],
                  courseStartDate: row['Course Start Date'],
                  courseEndDate: row['Course End Date'],
                  traineeName: row['Trainee Name'],
                  traineeGrade: row['Course Grade'],
                  traineeEmail: row['Trainee Email'],
                  issueDate: row['Issue Date'],
                })),
              }),
            );

            setIsLoading(true);

            axios
              .post('/api/process', data, { responseType: 'blob' })
              .then((resp) => {
                if (process.env.NODE_ENV !== 'development') {
                  const link = document.createElement('a');
                  link.href = window.URL.createObjectURL(new Blob([resp.data]));
                  link.download = `certs_${dayjs().format()}.zip`;
                  link.click();
                }

                notification.success({
                  message: 'Success',
                  description: `Sent to ${results.data.length} emails`,
                });
              })
              .catch((err) => {
                notification.error({
                  message: 'Error',
                  description:
                    typeof err.response?.data === 'string'
                      ? err.response?.data
                      : err.message,
                });
              })
              .finally(() => setIsLoading(false));
          },
        });
      }}
    >
      <Form.Item
        label="Email address of sender"
        name="emailFrom"
        rules={[
          {
            required: true,
            message: 'Please input the email address of sender!',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Email subject"
        name="emailSubject"
        rules={[
          {
            required: true,
            message: 'Please input the subject of the email!',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Email content"
        name="emailContent"
        rules={[
          {
            required: true,
            message: 'Please input the content of the email!',
          },
        ]}
      >
        <Input.TextArea autoSize={{ minRows: 6 }} />
      </Form.Item>

      <Form.Item
        label="Signature of instructor"
        name="signature"
        rules={[
          {
            required: true,
            message: 'Please upload a signature file!',
          },
        ]}
      >
        <Upload.Dragger
          accept="image/*"
          multiple={false}
          beforeUpload={() => false}
        >
          <p className="ant-upload-drag-icon">
            <UploadOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
        </Upload.Dragger>
      </Form.Item>

      <Form.Item
        label="CSV data"
        name="data"
        rules={[
          {
            required: true,
            message: 'Please upload the csv data!',
          },
        ]}
      >
        <Upload.Dragger
          accept=".csv"
          multiple={false}
          beforeUpload={() => false}
        >
          <p className="ant-upload-drag-icon">
            <UploadOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
        </Upload.Dragger>
      </Form.Item>

      <Form.Item className={styles.button}>
        <Button type="primary" htmlType="submit" disabled={isLoading}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default App;
